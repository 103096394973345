<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <delete-issue
      v-if="showDelete"
      :title="'Delete this ticket ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-face-agent
      </v-icon> Support Tickets
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        class="text-right"
      >
        <v-btn
          color="#FF3700"
          @click="showForm = true"
        >
          Report Issue
        </v-btn>
      </v-col>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="finalIssueList"
        :search="search"
        disable-sort
        class="elevation-1"
        mobile-breakpoint="100"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.name }}</td>
            <td>{{ item.type | typeFormat }}</td>
            <td>
              <font v-if="item.client_reference !== null">
                {{ item.client_reference }}
              </font>
              <font v-else>
                NA
              </font>
            </td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              <font
                :color="getColor(item.status)"
                class="font-my-style"
              >
                {{ item.status | statusUpdate }}
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click.stop
                @click="startChat(item.id)"
              >
                <v-icon>mdi-chat-plus</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click="deleteIssue(item.id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
      <div>
        <create-ticket
          v-if="showForm"
          @closed="showForm = false"
          @get-latest-tickets="showTickets"
        />
      </div>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import moment from 'moment';
  import CreateTicket from './CreateTicket.vue';
  import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';

  export default {
    name: 'Support',
    components: {
      'create-ticket': CreateTicket,
      'delete-issue': DeleteDialog,
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      statusUpdate (status) {
        if (status === 'open') {
          return 'Open';
        } else if (status === 'inProgress') {
          return 'Working';
        } else if (status === 'done') {
          return 'Done*';
        } else {
          return 'Parked';
        }
      },
      typeFormat (val) {
        if (val === 'saas') {
          return 'SaaS';
        }
        return 'App';
      },
    },
    data () {
      return {
        search: '',
        showForm: false,
        showDelete: false,
        deleteId: '',
        headers: [
          { text: 'Ticket Name', align: 'start', value: 'name' },
          { text: 'Type', value: 'type' },
          { text: 'Reference No.', value: 'client_reference' },
          { text: 'Reported At', value: 'created_at' },
          { text: 'Status', value: 'status' },
          { text: 'Chat', value: 'chat' },
          { text: 'Delete', value: 'delete' },
        ],
        loading: false,
      };
    },
    computed: {
      finalIssueList () {
        return this.$store.getters['support/getFinalIssueList'];
      },
      organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
    },
    async mounted () {
     await this.showTickets();
    },
    methods: {
      async showTickets () {
        this.loading = true;
        await this.$store.dispatch('support/fetchSupportTickets', {
          params: {
            orderBy: 'created_at',
          },
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
      deleteIssue (ticketId) {
        this.deleteId = ticketId;
        this.showDelete = true;
      },
      async deleteConfirmed () {
        this.showDelete = false;
        this.loading = true;
        await this.$store.dispatch('support/deleteIssueTicket', this.deleteId).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Ticket deleted successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.showTickets();
        }).catch(() => {
          this.loading = false;
        });
      },
      getColor (status) {
        if (status === 'open') {
          return '#2E7D32';
        } else if (status === 'inProgress') {
          return '#EF6C00';
        } else if (status === 'done') {
          return '#37474F';
        } else {
          return '#C62828';
        }
      },
      startChat (ticketId) {
        this.$router.push(`/support/tickets/${ticketId}/chats`);
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.font-my-style {
  font-weight: bold;
}
.authorization-alert {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}
</style>
